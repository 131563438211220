import React, { ReactElement } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

// Style
import '../styles/LoaderStyles.scss';

function Loader(): ReactElement {
  return (
    <div className="loader-container">
      <CircularProgress
        className="spinner"
      />
    </div>
  );
}

export default Loader;
