import React, { ReactElement, useState } from 'react';

// Components
import HamburgerMenu, { NavLinksInterface } from '../components/HamburgerMenu';
import PopUp from '../components/PopUp';
import FAQSection from '../components/FAQSection';
import ContactForm from '../components/ContactForm';

// Assets
import { ReactComponent as Logo } from '../assets/images/dealing.svg';
import { ReactComponent as Isotype } from '../assets/images/isotype.svg';
import { ReactComponent as Menu } from '../assets/images/landing/menu.svg';
import { ReactComponent as GestionIcon } from '../assets/images/landing/gestion.svg';
import { ReactComponent as DatosIcon } from '../assets/images/landing/datos.svg';
import { ReactComponent as VentasIcon } from '../assets/images/landing/ventas.svg';
import { ReactComponent as WhatsappIcon } from '../assets/images/landing/whatsapp.svg';
import { ReactComponent as MailIcon } from '../assets/images/landing/mail.svg';
import topContainerImage from '../assets/images/landing/top-container.png';
import conocenosImage from '../assets/images/landing/conocenos.jpg';
import partnerHubImage from '../assets/images/landing/partner-hub.png';
import phone01Image from '../assets/images/landing/phone-01.png';
import phone02Image from '../assets/images/landing/phone-02.png';
import phone03Image from '../assets/images/landing/phone-03.png';

// Styles
import '../styles/LandingStyles.scss';

function LandingView(): ReactElement {
  const navLinks: NavLinksInterface[] = [
    {
      key: '#conocenos',
      value: 'Conócenos',
    },
    {
      key: '#beneficios',
      value: 'Beneficios',
    },
    {
      key: '#dashboard',
      value: 'Dashboard',
    },
    {
      key: '#clientes',
      value: 'Clientes',
    },
    {
      key: '#faqs',
      value: 'FAQs',
    },
    {
      key: '#contacto',
      value: 'Contacto',
    },
  ];

  const popUpContent = {
    correct: {
      title: 'Mensaje enviado con éxito',
      body: 'Gracias por escribirnos,\nte contactaremos prontamente.',
      button: 'Aceptar',
    },
    incorrect: {
      title: 'Mensaje no enviado',
      body: 'Oops algo salió mal.\nPor favor vuelve a intentarlo.',
      button: 'Aceptar',
    },
  };

  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [popUpKey, setPopUpKey] = useState<'correct' | 'incorrect'>();

  return (
    <div className="App">
      {(openMenu) && (
        <HamburgerMenu
          navLinks={navLinks}
          closeMenu={() => { setOpenMenu(false); }}
        />
      )}
      {(popUpKey !== undefined) && (
        <PopUp
          title={popUpContent[popUpKey].title}
          body={popUpContent[popUpKey].body}
          button={popUpContent[popUpKey].button}
          closePopUp={() => { setPopUpKey(undefined); }}
        />
      )}
      <nav className="navbar">
        <div className="navbar-content">
          <a className="logo" href="#top">
            <Logo />
          </a>
          <div className="links">
            {navLinks.map(({ key, value }) => (
              <a
                key={key}
                href={key}
              >
                {value}
              </a>
            ))}
          </div>
          <button
            type="button"
            className="menu"
          >
            <Menu onClick={() => { setOpenMenu(true); }} />
          </button>
        </div>
      </nav>
      <div className="landing-content">
        <div className="top-container">
          <div className="text-container">
            <p className="title">
              Gestiona de manera eficiente tu Canal Tradicional
            </p>
            <p className="text">
              {'Con Dealing automatiza tus procesos de ventas, gestiona tu cartera '}
              de clientes y expande tu negocio en un solo lugar.
            </p>
            <a
              href="#contacto"
              className="contact-button"
            >
              Contáctanos
            </a>
          </div>
          <div className="image-container">
            <img src={topContainerImage} alt="Aplicaciones web y móvil" />
          </div>
        </div>
        <div id="conocenos">
          <div className="text-container">
            <p className="title">
              Acerca de Dealing
            </p>
            <p className="text">
              {'En Dealing diseñamos, desarrollamos e implementamos '}
              {'soluciones para hacer más eficiente la gestión de tu '}
              Canal Tradicional. Llevamos 4 años automatizando y simplificando procesos de ventas.
            </p>
          </div>
          <div className="image-container">
            <img
              src={conocenosImage}
              alt="Aplicacion web en fondo azul"
            />
          </div>
        </div>
        <div id="beneficios">
          <div className="left">
            <p>
              Beneficios para tu negocio
            </p>
          </div>
          <div className="right">
            <div className="bubble bubble-blue">
              <div className="title">
                <p>
                  Gestión
                </p>
              </div>
              <div className="text">
                <ul>
                  <li>
                    Cartera de Clientes.
                  </li>
                  <li>
                    Desempeño de Fuerza de Ventas.
                  </li>
                  <li>
                    Procesos de ventas simplificados.
                  </li>
                </ul>
              </div>
              <div className="icon">
                <GestionIcon />
              </div>
            </div>
            <div className="bubble bubble-white">
              <div className="title">
                <p>
                  Datos
                </p>
              </div>
              <div className="text">
                <ul>
                  <li>
                    Data actualizada de Clientes.
                  </li>
                  <li>
                    Reportes de Ventas.
                  </li>
                  <li>
                    Toma de Decisiones Informada.
                  </li>
                </ul>
              </div>
              <div className="icon">
                <DatosIcon />
              </div>
            </div>
            <div className="bubble bubble-blue">
              <div className="title">
                <p>
                  Ventas
                </p>
              </div>
              <div className="text">
                <ul>
                  <li>
                    Cobertura sin límites.
                  </li>
                  <li>
                    Comunicación rápida y eficiente con clientes.
                  </li>
                  <li>
                    Optimización de Clientes Nuevos.
                  </li>
                </ul>
              </div>
              <div className="icon">
                <VentasIcon />
              </div>
            </div>
          </div>
        </div>
        <div id="dashboard">
          <div className="container">
            <div className="title">
              <p>
                Dashboard operacional
              </p>
            </div>
            <div className="text">
              <div className="left">
                <div className="title">
                  <p>
                    Partner Hub
                  </p>
                </div>
                <div className="text">
                  <p>
                    {'Maneja tus procesos de operación, de venta y tu cartera de clientes a través de nuestro PH.\n\n'}
                    {'Gestiona KPI como información de tus clientes, inventario de tus productos, '}
                    {'ventas y datos de vendedores.\n\n'}
                    {'Data actualizada para toma de decisiones estratégica.\n\n'}
                    Integración con tu ERP para una operación automatizada con procesos fluidos y completos.
                  </p>
                </div>
              </div>
              <div className="right">
                <span />
                <img
                  src={partnerHubImage}
                  alt="Vistas del Partner Hub"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="clientes">
          <div className="text">
            <p className="title">
              ¿Cómo funciona para tus clientes?
            </p>
            <p className="subtitle">
              {'Disponibilidad 24/7 para hacer pedidos.\n'}
              Comunicación directa sobre novedades y promociones.
            </p>
          </div>
          <div className="phones-container">
            <span />
            <div className="phone-container">
              <img
                src={phone01Image}
                alt="Aplicacion móbil productos"
              />
              <p className="number">
                01
              </p>
              <div className="text">
                Seleccionar tus productos y promociones.
              </div>
            </div>
            <div className="phone-container">
              <img
                src={phone02Image}
                alt="Aplicacion móbil carro"
              />
              <p className="number">
                02
              </p>
              <div className="text">
                Realizar su pedido.
              </div>
            </div>
            <div className="phone-container">
              <img
                src={phone03Image}
                alt="Aplicacion móbil orden confirmada"
              />
              <p className="number">
                03
              </p>
              <div className="text">
                Recibir el pedido, y tu podrás revisar todos los datos de la venta.
              </div>
            </div>
          </div>
        </div>
        <div id="faqs">
          <div className="left">
            <p className="title">
              {'Preguntas\n'}
              frecuentes
            </p>
          </div>
          <div className="right">
            <FAQSection
              title="¿Qué es Dealing?"
              text="Dealing es la solución completa para empresas B2B. Creamos una página web y una app móvil
              exclusivas para tu negocio, permitiendo ventas las 24 horas. Además, nuestro Partner Hub
              simplifica la gestión de stock, precios y promociones, manteniendo a tus clientes informados.
              Con herramientas de monitoreo integradas, puedes gestionar todas tus ventas en un solo lugar."
            />
            <FAQSection
              title="¿Cómo funciona Dealing?"
              text={'1. Carga tu Productos, Precios, Promociones y listo. Tus clientes ya podrán hacer sus pedidos.\n'
                + '2. Nos integramos con ERP para automatizar la operación de tus ventas.\n'
                + '3. Gestiona todo tu Canal Tradicional en un sólo lugar. En tu Partner Hub tendrás datos de ventas, '
                + 'cli entes, productos y todo lo que necesites para expandir tu negocio.'}
            />
            <FAQSection
              title="¿Porqué usar Dealing?"
              text="Por que es la manera más eficiente de automatizar y gestionar las ventas de tu Canal Tradicional.
              Dealing te ayuda a cambiar funciones manuales por automatizaciones para hacer tu canal tradicional uno
              mucho más eficiente."
            />
            <FAQSection
              title="¿A quién va dirigido Dealing?"
              text="A toda empresa que venda productos a otros negocios, ya sea para que tus clientes utilicen o
              revendan tus productos. Si quieres potenciar tus ventas anímate a usar Dealing."
            />
            <a
              href="#contacto"
              className="question"
            >
              ¿Tienes otra pregunta? Escríbenos.
            </a>
          </div>
        </div>
        <div id="contacto">
          <div className="container">
            <Isotype className="isotype" />
            <div className="left">
              <p className="title">
                Conversemos!
              </p>
              <p className="subtitle">
                Escríbenos y veamos cómo podemos mejorar tu negocio.
              </p>
            </div>
            <div className="right">
              <ContactForm setPopUpKey={setPopUpKey} />
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="content">
          <Logo className="logo" />
          <div className="info">
            <div className="left">
              <div className="container">
                <p className="title">
                  Menú
                </p>
                <a
                  href="#top"
                  className="item"
                >
                  Inicio
                </a>
                <a
                  href="#conocenos"
                  className="item"
                >
                  Conócenos
                </a>
                <a
                  href="#beneficios"
                  className="item"
                >
                  Beneficios
                </a>
                <a
                  href="#dashboard"
                  className="item"
                >
                  Dashboard
                </a>
                <a
                  href="#clientes"
                  className="item"
                >
                  Clientes
                </a>
                <a
                  href="#faqs"
                  className="item"
                >
                  FAQs
                </a>
                <a
                  href="#contacto"
                  className="item"
                >
                  Contacto
                </a>
              </div>
              <div className="container">
                <p className="title">
                  Legal
                </p>
                <a
                  className="item"
                  href="https://dealing-legal.s3.amazonaws.com/politicas.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Política de privacidad
                </a>
                <a
                  className="item"
                  href="https://dealing-legal.s3.amazonaws.com/terminosycondiciones.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  Términos y condiciones
                </a>
              </div>
            </div>
            <div className="right">
              <p className="title">
                Datos de contacto
              </p>
              <a
                className="link"
                href="https://wa.me/56951589080"
                target="_blank"
                rel="noreferrer"
              >
                <WhatsappIcon />
                <p>
                  Whatsapp
                </p>
              </a>
              <a
                className="link"
                href="mailto:contacto@dealingapp.com"
                target="_blank"
                rel="noreferrer"
              >
                <MailIcon />
                <p>
                  contacto@dealingapp.com
                </p>
              </a>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>
            Dealing © 2024 Copyright
          </p>
        </div>
      </footer>
    </div>
  );
}

export default LandingView;
