import React, {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

// Views
import LandingView from './views/LandingView';
import NotFoundView from './views/NotFoundView';

// Components
import Loader from './components/Loader';

// Styles
import './styles/App.scss';

function App(): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <Loader />
    );
  }

  return (
    <Router forceRefresh>
      <Switch>
        <Route exact path="/">
          <LandingView />
        </Route>
        <Route path="*">
          <NotFoundView />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
