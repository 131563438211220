import React, { ReactElement } from 'react';

import { ReactComponent as Close } from '../assets/images/landing/close.svg';

interface PopUpProps {
  title: string;
  body: string;
  button: string;
  closePopUp: () => void;
}

function PopUp(props: PopUpProps): ReactElement {
  const {
    title, body, button, closePopUp,
  } = props;

  return (
    <div className="popup-main">
      <div className="container">
        <div className="close">
          <Close onClick={() => { closePopUp(); }} />
        </div>
        <div className="content">
          <p className="title">
            {title}
          </p>
          <p className="body">
            {body}
          </p>
          <button
            type="button"
            className="button"
            onClick={() => { closePopUp(); }}
          >
            {button}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopUp;
