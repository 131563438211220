import React, { Dispatch, ReactElement } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import axios from 'axios';

interface PopUpProps {
  setPopUpKey: Dispatch<React.SetStateAction<'correct' | 'incorrect'>>
}

interface ContactFormInterface {
  name: string;
  phone: string;
  email: string;
  message: string;
}

function ContactForm(props: PopUpProps): ReactElement {
  const { setPopUpKey } = props;

  const {
    register,
    handleSubmit,
    reset,
  } = useForm();

  function handleSendContact(values: FieldValues) {
    const data = values as ContactFormInterface;
    let slackMessage = 'Contact from Dealing landing!\n\n';
    slackMessage += `Name: ${data.name}\n`;
    slackMessage += `Email: ${data.email}\n`;
    slackMessage += `Phone: ${data.phone}\n\n`;
    slackMessage += `${data.message}`;

    axios({
      method: 'post',
      url: 'https://hooks.slack.com/services/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',
      data: JSON.stringify({ text: slackMessage }),
    })
      .then(() => {
        reset();
        setPopUpKey('correct');
      })
      .catch(() => {
        setPopUpKey('incorrect');
      });
  }

  return (
    <form onSubmit={handleSubmit(handleSendContact)}>
      <label htmlFor="name">
        Nombre y apellido *
        <input
          type="text"
          {...register('name', { required: true })}
        />
      </label>
      <label htmlFor="phone">
        Teléfono *
        <input
          type="tel"
          {...register('phone', { required: true })}
        />
      </label>
      <label htmlFor="email">
        E-Mail *
        <input
          type="email"
          {...register('email', { required: true })}
        />
      </label>
      <label htmlFor="message">
        Mensaje
        <textarea
          placeholder="Escribe tu mensaje"
          {...register('message')}
        />
      </label>
      <button type="submit">
        Enviar
      </button>
    </form>
  );
}

export default ContactForm;
