import React, { ReactElement } from 'react';

import { ReactComponent as Close } from '../assets/images/landing/close.svg';

export interface NavLinksInterface {
  key: string;
  value: string;
}

interface HamburgerMenuProps {
  navLinks: NavLinksInterface[];
  closeMenu: () => void;
}

function HamburgerMenu(props: HamburgerMenuProps): ReactElement {
  const { navLinks, closeMenu } = props;

  return (
    <div className="hamburger-menu">
      <div className="close">
        <Close onClick={() => { closeMenu(); }} />
      </div>
      <div className="links">
        {navLinks.map(({ key, value }) => (
          <a
            key={key}
            href={key}
            onClick={() => { closeMenu(); }}
          >
            {value}
          </a>
        ))}
      </div>
    </div>
  );
}

export default HamburgerMenu;
