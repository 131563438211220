import React, { ReactElement, useState } from 'react';

// Assets
import { ReactComponent as ArrowIcon } from '../assets/images/landing/arrow.svg';

interface FAQSectionProps {
  title: string;
  text: string;
}

function FAQSection(props: FAQSectionProps): ReactElement {
  const {
    title,
    text,
  } = props;

  const [opened, setOpened] = useState<boolean>(false);

  return (
    <div className="section">
      <div
        className="title"
        onClick={() => { setOpened((prev) => !prev); }}
      >
        <p>
          {title}
        </p>
        <ArrowIcon data-opened={opened} />
      </div>
      <div
        className="text"
        data-opened={opened}
      >
        <p>
          {text}
        </p>
      </div>
    </div>
  );
}

export default FAQSection;
