import React, {
  ReactElement,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Assets
import topWing from '../assets/images/not-found-wing-top.png';
import botWing from '../assets/images/not-found-wing-bot.png';

// Styles
import '../styles/NotFoundStyles.scss';

function NotFoundView(): ReactElement {
  // translation, useTranslation(['file']) for other file
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <div className="not-found-main">
      <img
        src={topWing}
        alt="Alas superiores"
        className="top-wing"
      />
      <img
        src={botWing}
        alt="Alas inferiores"
        className="bot-wing"
      />
      <div className="content">
        <h1>
          404
        </h1>
        <h2>
          {t('NotFoundView.subTitle')}
        </h2>
        <p>
          {t('NotFoundView.description')}
        </p>
        <button
          type="button"
          onClick={() => history.push('/')}
        >
          {t('NotFoundView.back')}
        </button>
      </div>
    </div>
  );
}

export default NotFoundView;
